import React from "react";
import PropTypes from "prop-types";
import { DiscussionEmbed } from "disqus-react";

const Comments = props => {
  const { facebook, slug, theme, title } = props;

  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: slug, title }
  };

  return (
    <React.Fragment>
      <div id="post-comments" className="comments">
        <DiscussionEmbed {...disqusConfig} />
      </div>

      {/* --- STYLES --- */}
      <style jsx>{`
        .comments {
          margin: 0 -8px ${theme.space.default};
        }
      `}</style>
    </React.Fragment>
  );
};

Comments.propTypes = {
  slug: PropTypes.string.isRequired,
  facebook: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  title: PropTypes.string
};

export default Comments;
